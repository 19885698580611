import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import SettingsIcon from "@material-ui/icons/Settings";
import SendTwoTone from "@material-ui/icons/SendTwoTone";
import SmsIcon from "@material-ui/icons/Sms";
import {
    translate,
    DashboardMenuItem,
    MenuItemLink,
    Responsive
} from "react-admin";
import { withRouter } from "react-router-dom";
import { SliderIcon } from "./slider";
import { GalleryIcon } from "./gallery";
import { AreasIcon } from "./areas";
import { MarketsIcon } from "./markets";
import { PromoIcon } from "./promoCodes";
import { ContactUsIcon } from "./contactUs";
import { ProductsIcon } from "./products";
import { red500, green500, orange500 } from "material-ui/styles/colors";

import { OrdersIcon } from "./orders";
import { ProductCategoryIcon } from "./productCategory";
import { UsersIcon } from "./users";
import { withStyles } from "@material-ui/core/styles";
const items = [
    { name: "markets", icon: <MarketsIcon /> },
    { name: "products", icon: <ProductsIcon /> },
    { name: "slider", icon: <SliderIcon /> },
    { name: "productCategory", icon: <ProductCategoryIcon /> },
    // { name: "ProductsAddition", icon: <ProductsAdditionIcon /> },
    // {
    //   name: "ProductsAdditionCategory",
    //   icon: <ProductsAdditionCategoryIcon />
    // },
    { name: "orders", icon: <OrdersIcon /> },
    { name: "areas", icon: <AreasIcon /> },
    { name: "promocodes", icon: <PromoIcon /> },
    // { name: "contactUs", icon: <ContactUsIcon /> },
    { name: "users", icon: <UsersIcon /> }
];

const styles = {
    main: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "100%"
    },
    menu: {
        "& svg": { color: "#8b58a4" }
        // backgroundColor: '#84bf33',
        // color: '#ffffff',
    }
};

const Menu = ({ onMenuClick, translate, logout, classes }) => (
    <div style={styles.main} className={classes.menu}>
        <DashboardMenuItem onClick={onMenuClick} />
        {localStorage.getItem("subAdmin") !== "1"
            ? items.map((item, key) => (
                  <div key={"asd" + key}>
                      <MenuItemLink
                          key={key}
                          to={`/${item.name}`}
                          primaryText={translate(`${item.name}.name`, {
                              smart_count: 2
                          })}
                          leftIcon={item.icon}
                          onClick={onMenuClick}
                          className={classes.menu}
                      />
                  </div>
              ))
            : items.map(item => (
                  <div>
                      {[
                          "homeSlider",
                          "products",
                          "productCategory",
                          "ProductsAddition",
                          "ProductsAdditionCategory",
                          "orders"
                      ].indexOf(item.name) !== -1 && (
                          <MenuItemLink
                              key={item.name}
                              to={`/${item.name}`}
                              primaryText={translate(`${item.name}.name`, {
                                  smart_count: 2
                              })}
                              leftIcon={item.icon}
                              onClick={onMenuClick}
                              className={classes.menu}
                          />
                      )}
                  </div>
              ))}

        {/* {localStorage.getItem("subAdmin") !== "1" && (
      <MenuItemLink
        to="/configuration"
        primaryText={translate("pos.configuration")}
        leftIcon={<SettingsIcon />}
        onClick={onMenuClick}
        className={classes.menu}
      />
    )}
    {localStorage.getItem("subAdmin") !== "1" && (
      <MenuItemLink
        to="/generalNotification"
        primaryText={translate("pos.generalNotification")}
        leftIcon={<SendTwoTone />}
        onClick={onMenuClick}
        className={classes.menu}
      />
    )}
    {localStorage.getItem("subAdmin") !== "1" && (
      <MenuItemLink
        to="/productsorder"
        primaryText={translate("pos.productOrder")}
        leftIcon={<Reorder />}
        onClick={onMenuClick}
        className={classes.menu}
      />
    )} */}
        <MenuItemLink
            to="/generalNotification"
            primaryText={translate("pos.generalNotification")}
            leftIcon={<SendTwoTone />}
            onClick={onMenuClick}
            className={classes.menu}
        />
        <MenuItemLink
            to="/sms"
            primaryText={translate("pos.sms")}
            leftIcon={<SmsIcon />}
            onClick={onMenuClick}
            className={classes.menu}
        />
        {/* <MenuItemLink
            to="/configuration"
            primaryText={translate("pos.configuration")}
            leftIcon={<SettingsIcon />}
            onClick={onMenuClick}
            className={classes.menu}
        /> */}

        <Responsive xsmall={logout} medium={null} />
    </div>
);

const enhance = compose(
    withRouter,
    withStyles(styles),
    connect(
        state => ({
            theme: state.theme,
            locale: state.i18n.locale
        }),
        {}
    ),
    translate
);

export default enhance(Menu);
