import React from "react";
import {
    translate,
    Create,
    Datagrid,
    Edit,
    EditButton,
    DeleteButton,
    Filter,
    SimpleForm,
    List,
    BooleanField,
    Show,
    SimpleShowLayout,
    ShowButton,
    ImageInput,
    ImageField,
    FunctionField,
    BooleanInput,
    required,
    maxLength,
    minLength,
    number,
    TextField,
    TextInput,
    CardActions,
    CreateButton,
    RefreshButton
} from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/SupervisedUserCircle";
import Poster from "./Poster";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import SendNotification from "./SendNotification";
export const UsersIcon = Icon;

function booleanCheck(val) {
    if (val) {
        return <Check />;
    } else {
        return <Close />;
    }
}

const UserFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        {/* <TextInput label="Title" source="title" defaultValue="Hello, World!" /> */}
    </Filter>
);

const UserActions = ({
    bulkActions,
    basePath,
    displayedFilters,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter
}) => (
    <CardActions>
        {bulkActions &&
            React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems
            })}
        {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: "button"
            })}
        <CreateButton basePath={basePath} />
        <RefreshButton />
    </CardActions>
);

export const UsersList = props => (
    <List {...props} actions={<UserActions />} filters={<UserFilter />}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="phone_number" />
            <FunctionField
                label="Facebook"
                render={record => booleanCheck(record.social_id)}
            />
            <TextField source="device" />
            <SendNotification />
            <ShowButton />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

const createStyles = {
    stock: { width: "5em" },
    price: { width: "5em" },
    width: { width: "5em" },
    widthFormGroup: { display: "inline-block" },
    height: { width: "5em" },
    heightFormGroup: { display: "inline-block", marginLeft: 32 },
    button: { margin: "1em" },
    input: { width: "500px !important" }
};

function postData(data, email, id) {
    let idd = id ? id : 0;
    let Email = email ? email : 0;
    let url =
        process.env.REACT_APP_CLIENT_URL +
        "/api/backend/checkMobileNo/" +
        data +
        "?user_id=" +
        idd +
        "?email=" +
        Email;
    // Default options are marked with *
    return fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, same-origin, *omit
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
            "X-Device": "front",
            Authorization: "Bearer " + localStorage.getItem("token"),
            "X-lang": "en"

            // "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer" // no-referrer, *client
        //        body: JSON.stringify(data), // body data type must match "Content-Type" header
    }).then(response => response.json()); // parses response to JSON
}

const asyncValidate = async (values, dispatch, props, field) => {
    return new Promise(async (resolve, reject) => {
        let err = props.asyncErrors ? props.asyncErrors : [];
        if (field === "phone_number") {
            const data = await postData(
                values.phone_number,
                // values.email,
                values.id
            );
            if (data.success === false) {
                err.phone_number = "The mobile number is already exsits!";
                reject(err);
            } else {
                if (Object.keys(err).length !== 0) {
                    err.splice("phone_number", 1);
                }
            }
        }
        if (field === "email") {
            const data = await postData(
                // values.phone_number,
                values.email,
                values.id
            );
            if (data.success === false) {
                err.email = "The email is already exsits!";
                reject(err);
            } else {
                if (Object.keys(err).length !== 0) {
                    err.splice("email", 1);
                }
            }
        }
        if (Object.keys(err).length === 0) {
            resolve();
        }
    });
};
export const UsersCreate = withStyles(createStyles)(({ classes, ...props }) => (
    <Create {...props}>
        <SimpleForm
            redirect="list"
            asyncValidate={asyncValidate}
            asyncBlurFields={["phone_number"]}
        >
            <TextInput
                className={classes.input}
                source="name"
                validate={required()}
            />
            <TextInput
                className={classes.input}
                source="phone_number"
                validate={[required(), number(), minLength(11), maxLength(11)]}
            />
            <TextInput
                className={classes.input}
                label="Password"
                source="password"
                type="password"
                validate={required()}
            />
            <TextInput
                className={classes.input}
                label="Re-Password"
                source="c_password"
                type="password"
                validate={passConfirmValidate}
            />
            <ImageInput source="image" label="User photo" accept="image/*">
                <ImageField source="image" title="title" />
            </ImageInput>
            <BooleanInput source="is_admin" />
            <BooleanInput source="sms_verified" />
        </SimpleForm>
    </Create>
));

const UsersTitle = translate(({ record, translate }) => (
    <span>
        {translate("users.name")} #{record.id}
    </span>
));

const editStyles = {
    ...createStyles,
    comment: {
        maxWidth: "20em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        input: { width: "500px !important" }
    }
};

const passValidation = (value, allValues) => {
    if (allValues.password !== value) {
        return "Password confirmation doesn't match with password";
    }
    return undefined;
};
const passConfirmValidate = [passValidation];

export const UsersEdit = withStyles(editStyles)(({ classes, ...props }) => (
    <Edit {...props} title={<UsersTitle />}>
        <SimpleForm
            redirect="list"
            asyncValidate={asyncValidate}
            asyncBlurFields={["phone_number", "email"]}
        >
            <Poster />
            <TextInput
                className={classes.input}
                source="name"
                validate={required()}
            />
            <TextInput
                className={classes.input}
                source="phone_number"
                validate={[required(), number(), minLength(11), maxLength(11)]}
            />
            <TextInput
                className={classes.input}
                label="Password"
                source="password"
                type="password"
            />
            <TextInput
                className={classes.input}
                label="Re-Password"
                source="c_password"
                type="password"
                validate={passConfirmValidate}
            />
            <ImageInput source="photos" label="User photo" accept="image/*">
                <ImageField source="photos" title="title" />
            </ImageInput>
            <BooleanInput source="is_admin" />
            <BooleanInput source="sms_verified" />
        </SimpleForm>
    </Edit>
));

export const UserShow = withStyles(editStyles)(({ classes, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField className={classes.input} source="name" />
            <TextField
                className={classes.input}
                source="phone_number"
                required
            />
            <FunctionField
                label="is_admin"
                render={record => booleanCheck(record.is_admin)}
            />
            <Poster />
        </SimpleShowLayout>
    </Show>
));
