import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";

import {
  orderApprove as orderApproveAction,
  orderReject as orderRejectAction
} from "./orderActions";

const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: orange
  },
  typography: {
    useNextVariants: true
  }
});

const styles = theme => ({
  textColor: {
    color: "#ffffff",
    fontSize: 10,
    margin: 5
  }
});

class ApproveButton extends Component {
  handleApprove = () => {
    const { orderApprove, record } = this.props;
    orderApprove(record.id, record);
  };

  handleReject = () => {
    const { orderReject, record } = this.props;
    orderReject(record.id, record);
  };

  render() {
    const { record } = this.props;
    const { classes } = this.props;
    if (record.status === "done") {
      return null;
    }
    if (record.status === "prepared") {
      return (
      <span>
      <MuiThemeProvider theme={theme}>
  
        <Button
          variant="contained"
          className={classes.textColor}
          color="primary"
          onClick={this.handleApprove}
        >
          Done
        </Button>
      </MuiThemeProvider>
    </span>
      )
    }
    return (
      <span>
        <MuiThemeProvider theme={theme}>
          
          <Button
            variant="contained"
            className={classes.textColor}
            color="secondary"
            onClick={this.handleReject}
          >
            Prepare
          </Button>
          <Button
            variant="contained"
            className={classes.textColor}
            color="primary"
            onClick={this.handleApprove}
          >
            Done
          </Button>
        </MuiThemeProvider>
      </span>
    );
  }
}

ApproveButton.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object,
  orderApprove: PropTypes.func,
  orderReject: PropTypes.func
};

export default connect(
  null,
  {
    orderApprove: orderApproveAction,
    orderReject: orderRejectAction
  }
)(withStyles(styles)(ApproveButton));
