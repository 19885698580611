import englishMessages from "ra-language-english";

export default {
    ...englishMessages,
    auth: {
        username: "Username",
        email: "Email",
        password: "Password",
        sign_in: "Sign in",
        sign_in_error: "Authentication failed, please retry",
        logout: "Logout"
    },
    slider: {
        name: "Main slider"
    },
    gallery: {
        name: "Gallery"
    },
    contactUs: {
        name: "Contact us entries"
    },
    areas: {
        name: "Areas"
    },
    markets: {
        name: "Markets"
    },
    products: {
        name: "Products"
    },
    productCategory: {
        name: "Product Category"
    },
    promocodes: {
        name: "Promo Code"
    },
    ProductsAddition: {
        name: "Products Additions"
    },
    ProductsAdditionCategory: {
        name: "Additions Categories"
    },
    users: {
        name: "Users",
        new_users: "New users",
        total_users: "Registered Customers",
        total_orders: "Total Orders"
    },
    orders: {
        name: "Orders",
        pending: "Pending Orders",
        today_orders: "Today Orders"
    },
    homeData: {
        about: "About section",
        contacts: "Contacts section",
        facebook: "Facebook URL",
        instagram: "Instagram URL",
        snapchat: "Snapchat URL"
    },
    pos: {
        search: "Search",
        configuration: "Configuration",
        productOrder: "Items order",
        sms: "Send SMS",
        generalNotification: "Send notification",
        language: "Admin Language",
        theme: {
            name: "Admin Theme",
            light: "Light",
            dark: "Dark"
        },
        dashboard: {
            monthly_revenue: "Monthly Revenue",
            new_orders: "New Orders",
            pending_reviews: "Pending Reviews",
            new_customers: "New Customers",
            pending_orders: "Pending Orders",
            order: {
                items:
                    "by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items"
            },
            welcome: {
                title: "Welcome to react-admin demo",
                subtitle:
                    "This is the admin of an imaginary poster shop. Fell free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                aor_button: "react-admin site",
                demo_button: "Source for this demo"
            }
        }
    },
    resources: {
        customers: {
            name: "Customer |||| Customers",
            fields: {
                commands: "Orders",
                groups: "Segments",
                last_seen_gte: "Visited Since",
                name: "Name",
                total_spent: "Total spent"
            },
            tabs: {
                identity: "Identity",
                address: "Address",
                orders: "Orders",
                reviews: "Reviews",
                stats: "Stats"
            },
            page: {
                delete: "Delete Customer"
            }
        },
        commands: {
            name: "Order |||| Orders",
            fields: {
                basket: {
                    delivery: "Delivery",
                    reference: "Reference",
                    quantity: "Quantity",
                    sum: "Sum",
                    tax_rate: "Tax Rate",
                    total: "Total",
                    unit_price: "Unit Price"
                },
                customer_id: "Customer",
                date_gte: "Passed Since",
                date_lte: "Passed Before",
                total_gte: "Min amount",
                status: "Status",
                returned: "Returned"
            }
        },
        products: {
            name: "Product |||| Products",
            fields: {
                category_id: "Category",
                height_gte: "Min height",
                height_lte: "Max height",
                height: "Height",
                image: "Image",
                price: "Price",
                reference: "Reference",
                stock_lte: "Low Stock",
                stock: "Stock",
                thumbnail: "Thumbnail",
                width_gte: "Min width",
                width_lte: "Max width",
                width: "Width"
            },
            tabs: {
                image: "Image",
                details: "Details",
                description: "Description",
                reviews: "Reviews"
            }
        },
        categories: {
            name: "Category |||| Categories",
            fields: {
                products: "Products"
            }
        },
        reviews: {
            name: "Review |||| Reviews",
            fields: {
                customer_id: "Customer",
                command_id: "Order",
                product_id: "Product",
                date_gte: "Posted since",
                date_lte: "Posted before",
                date: "Date",
                comment: "Comment",
                rating: "Rating"
            },
            action: {
                accept: "Accept",
                reject: "Reject"
            },
            notification: {
                approved_success: "Review approved",
                approved_error: "Error: Review not approved",
                rejected_success: "Review rejected",
                rejected_error: "Error: Review not rejected"
            }
        },
        segments: {
            name: "Segments",
            fields: {
                customers: "Customers",
                name: "Name"
            },
            data: {
                compulsive: "Compulsive",
                collector: "Collector",
                ordered_once: "Ordered once",
                regular: "Regular",
                returns: "Returns",
                reviewer: "Reviewer"
            }
        }
    }
};
