import React from "react";
import {
    translate,
    Create,
    Datagrid,
    Edit,
    ArrayField,
    DeleteButton,
    AutocompleteInput,
    Filter,
    SimpleForm,
    NumberInput,
    ImageInput,
    ImageField,
    ReferenceInput,
    Show,
    SimpleShowLayout,
    ShowButton,
    DisabledInput,
    SelectInput,
    TextField,
    ReferenceField,
    TextInput,
    BooleanInput,
    required
} from "react-admin";
import { DateInput } from "react-admin-date-inputs";
import List from "../customs/List";
import Chip from "@material-ui/core/Chip";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/Shop";
import Poster from "./Poster";
import DoneButton from "./doneButton";

export const OrdersIcon = Icon;

const QuickFilter = translate(({ label, translate }) => (
    <Chip>{translate(label)}</Chip>
));

export const OrdersFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput
            source="user_id"
            reference="users"
            sort={{ field: "id", order: "ASC" }}
        >
            <AutocompleteInput source="name" enableSearch={true} />
        </ReferenceInput>
        <DateInput source="ordered_on" label="Ordered on" />
        <DateInput source="from_date" label="From date" />
        <DateInput source="to_date" label="To date" />

        {/* <QuickFilter
      label="resources.Orders.fields.stock_lte"
      source="stock_lte"
      defaultValue={10}
    /> */}
    </Filter>
);

const RowStyle = (record, index) => ({
    backgroundColor: record.status === "Deleted" ? "#eaad" : "white"
});

export const OrdersList = props => (
    <List title="Orders" {...props} filters={<OrdersFilter />}>
        <Datagrid rowStyle={RowStyle}>
            <TextField source="id" />
            <ReferenceField
                label="User"
                source="user_id"
                reference="users"
                linkType="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <TextField source="total_price" label="Total" />
            <TextField source="platform" label="Platform" />
            <TextField source="status" />
            <TextField source="created_at" />
            <ShowButton />
            {/* <DoneButton /> */}
        </Datagrid>
    </List>
);

const createStyles = {
    stock: { width: "5em" },
    price: { width: "5em" },
    width: { width: "5em" },
    widthFormGroup: { display: "inline-block" },
    height: { width: "5em" },
    heightFormGroup: { display: "inline-block", marginLeft: 32 }
};

const handleChangeImage = evt => {};

export const OrdersCreate = withStyles(createStyles)(
    ({ classes, ...props }) => (
        <Create {...props} title="Create porduct">
            <SimpleForm redirect="list">
                <TextInput source="name" label="Product name" />
                <ReferenceInput
                    source="product_category_id"
                    label="Product category"
                    reference="productCategory"
                >
                    <SelectInput optionText="name" optionValue="id" />
                </ReferenceInput>
                <NumberInput source="price" />
                <BooleanInput label="In stock" source="in_stock" />
                <BooleanInput label="Featured" source="featured" />
                <ImageInput
                    source="image"
                    label="Related pictures"
                    accept="image/*"
                >
                    <ImageField source="image" title="title" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
);

const OrdersTitle = translate(({ record, translate }) => (
    <span>
        {translate("Orders.name")} #{record.id}
    </span>
));

const editStyles = {
    ...createStyles,
    comment: {
        maxWidth: "20em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    input2: {
        maxWidth: "20em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    bold: {
        fontWeight: "bold"
    }
};

export const OrdersEdit = withStyles(editStyles)(({ classes, ...props }) => (
    <Edit {...props} title={<OrdersTitle />}>
        <SimpleForm>
            <Poster />
            <ImageInput
                source="image"
                onChange={handleChangeImage}
                label="Related pictures"
                accept="image/*"
            >
                <ImageField
                    source="image"
                    onChange={handleChangeImage}
                    title="title"
                />
            </ImageInput>
            <DisabledInput label="Id" source="id" />
            <TextInput source="name" validate={required()} />

            <NumberInput source="price" />
            <BooleanInput label="In stock" source="in_stock" />
            <BooleanInput label="Featured" source="featured" />
            <ReferenceInput
                source="product_category_id"
                label="Product category"
                reference="productCategory"
            >
                <SelectInput optionText="name" optionValue="id" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
));

export const OrderShow = withStyles(editStyles)(({ classes, ...props }) => (
    <Show {...props} actions={false}>
        <SimpleShowLayout>
            <TextField
                className={classes.input2}
                style={{ display: "inline" }}
                source="id"
            />
            <TextField className={classes.input2} source="promo_code" />
            <TextField className={classes.input2} source="discount" />
            <TextField className={classes.input2} source="fee" />
            <TextField className={classes.input2} source="status" />
            <TextField
                className={classes.input2}
                source="total_before_discount"
                title="Total before discount"
            />
            <TextField className={classes.input2} source="total_price" />
            <ReferenceField
                label="User"
                source="user_id"
                reference="users"
                linkType="show"
            >
                <TextField source="name" />
            </ReferenceField>

            <TextField source="user_address.area" />
            <TextField source="user_address.building_number" />
            <TextField source="user_address.floor_number" />
            <TextField source="user_address.phone_number" />
            <TextField source="user_address.special_sign" />
            <TextField source="user_address.street_name" />
            <TextField source="notes" />

            <ArrayField source="products" label="Products">
                <Datagrid>
                    <ReferenceField
                        label="Product name"
                        source="id"
                        reference="products"
                        linkType="show"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="quantity" />
                    <TextField source="total_price" />
                </Datagrid>
            </ArrayField>

            {/* <ReferenceManyField
        label="Comments by"
        reference="products"
        source="order_detailes"
        target="id"
      >
          <ChipField source="name" />
          <Datagrid >
                    <TextField source="name" />
                    <ReferenceField
                      label="User"
                      source="product_id"
                      reference="products"
                      linkType="show"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    
                    
                </Datagrid>
      </ReferenceManyField> */}

            {/* <TextField className={classes.input} source="landline_number" required />
        <TextField className={classes.input} source="mobile_number" required />
        <TextField className={classes.input} source="address" required />
        <TextField className={classes.input} label="Password" source="password" type="password" />
        <Poster />
        <BooleanField source="is_admin" />
        <BooleanField source="confirmed" /> */}
        </SimpleShowLayout>
    </Show>
));
