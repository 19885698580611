import React, { Component } from "react";
import { AppBar } from "react-admin";
import { Field, reduxForm } from "redux-form";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import NativeSelect from "@material-ui/core/NativeSelect";
import { branch } from "recompose";
// import classes from "*.module.css";

const renderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
}) => {
    console.log(children);
    return (
        <SelectField
            errorText={touched && error}
            {...input}
            onChange={(event, index, value) => input.onChange(value)}
            children={children}
            {...custom}
        />
    );
};

// import Logo from './Logo';

const styles = {
    title: {
        flex: 1,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
    },
    spacer: {
        flex: 1
    },
    logoImage: {
        backgroundColor: "#fff",
        width: "112px",
        padding: "8px",
        marginLeft: "180px",
        borderRadius: "14px"
    },
    selectOption: {
        color: "#fff"
    },
    barSelect: {
        color: "#fff",
        height: "48px !important",
        "& label": {
            top: "12px !important",
            color: "white !important"
        },
        "& option": {
            color: "#fff",
            backgroundColor: "#8b58a4"
        },
        "& svg": {
            color: "#fff"
        },
        "& select": {
            padding: "14px 24px 7px"
        }
    }
};

class MyAppBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMarket: localStorage.getItem("marketId")
                ? localStorage.getItem("marketId")
                : 0,
            branches: false
        };
        this.handleChange = this.handleChange.bind(this);
    }
    componentWillMount() {
        if (!this.state.branches) {
            this.getBranches();
        }
    }
    handleChange(event) {
        if (event) {
            this.setState({
                selectedMarket: event.target.value
            });
            localStorage.setItem("marketId", event.target.value);
            console.log(window.location.href);
            // console.log(this.props.history);
            window.location.reload();
        }
    }
    getBranches() {
        const request = new Request(
            process.env.REACT_APP_CLIENT_URL + "/api/branches",
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "X-Authorization-Token": "application/json",
                    "X-Language": "ar",
                    "Client-Type": "web",
                    "Access-Control-Allow-Origin": "*"
                })
            }
        );
        fetch(request)
            .then(res => res.json())
            .then(result => {
                this.setState({
                    branches: result
                });
            });
    }
    render() {
        const { classes } = this.props;
        return (
            <MuiThemeProvider>
                <AppBar {...this.props}>
                    <Typography
                        variant="title"
                        color="inherit"
                        className={classes.title}
                        id="react-admin-title"
                    />
                    <img className={classes.logoImage} src="./logo.png" />
                    <span className={classes.spacer} />
                    <NativeSelect
                        value={this.state.selectedMarket}
                        onChange={this.handleChange}
                        className={classes.barSelect}
                        inputProps={{
                            name: "age",
                            id: "age-native-helper"
                        }}
                    >
                        <option value="0">All branches</option>
                        {this.state.branches &&
                            this.state.branches.map(function(item, index) {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                );
                            })}
                    </NativeSelect>
                </AppBar>
            </MuiThemeProvider>
        );
    }
}

// const MyAppBar = withStyles(styles)(({ classes, ...props }) => {
//     return (
//         <AppBar {...props}>
//             <Typography
//                 variant="title"
//                 color="inherit"
//                 className={classes.title}
//                 id="react-admin-title"
//             />
//             <img className={classes.logoImage} src="./logo.png" />
//             <span className={classes.spacer} />
//             <div>11</div>
//         </AppBar>
//     );
// });

// export default MyAppBar;

export default reduxForm({
    form: "MyAppBar"
})(withStyles(styles)(MyAppBar));
