import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Field, reduxForm } from "redux-form";
import TextField from "material-ui/TextField";
import Switch from "@material-ui/core/Switch";
import submit from "./submit";
import { createMuiTheme } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import red from "@material-ui/core/colors/red";
import Snackbar from "@material-ui/core/Snackbar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import { DateInput, TimeInput } from "react-admin-date-inputs";
import SaveIcon from "@material-ui/icons/Save";
import SyncIcon from "@material-ui/icons/Sync";
import { blue } from "@material-ui/core/colors";

const themE = createMuiTheme({});
const styles = theme => ({
  card: {
    maxWidth: "100%",
    padding: "20px"
  },
  actions: {
    display: "flex"
  },
  header: {
    fontSize: 50
  },
  label: {
    color: blue
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500]
  },
  input_hidden: {
    display: "none !important"
  },
  sync_button: {
    marginLeft: "5px"
  },
  swich_button: {
    color: "blue !important",
    marginLeft: "4px",
    span: {
      color: "blue !important"
    }
  },
  swich_button_hidden: {
    color: "blue !important",
    display: "none",
    marginLeft: "4px",
    span: {
      color: "blue !important"
    }
  }
});

const validate = values => {
  const errors = {};
  const requiredFields = [
    "firstName",
    "lastName",
    "email",
    "favoriteColor",
    "notes"
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }
  return errors;
};

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
    <TextField
      hintText={label}
      floatingLabelText={label}
      errorText={touched && error}
      {...input}
      {...custom}
    />
  );

const renderRadio = ({ input, label, meta: { touched, error }, ...custom }) => (
  <Switch
    hintText={label}
    checked={input.value}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
);


class GeneralNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMassege: false,
      openMassegeAlert: false,
      classHeddin: "input_hidden",
      notificationMessage: "Syncing ...!",
      classWorkingHeddin: "input_hidden"
    };

  }

  async componentDidMount() {
    this.handleInitialize();
  }

  handleInitialize() {
    const request = new Request(
      process.env.REACT_APP_CLIENT_URL + "/api/homeData?data=1",
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "X-Authorization-Token": "12b20fa6cca0ee113dc92d16f6be3029",
          Authorization: "Bearer " + localStorage.getItem("token")
        })
      }
    );
    return fetch(request)
      .then(response => response.json())
      .then(data => {
        const initData = {
          about: data.about,
          contacts: data.contacts,
          about_ar: data.about_ar,
          contacts_ar: data.contacts_ar,
          facebook: data.facebook,
          instagram: data.instagram,
          snapchat: data.snapchat,
          open_from: data.open_from,
          open_to: data.open_to,
          closed: data.closed,
          closedMessage: data.closedMessage,
          closedMessageOutTime: data.closedMessageOutTime,
          valid_until: data.valid_until,
          discount: data.discount
        };
        if (data.discount > 0) {
          this.setState({ classHeddin: "input" });
        }
        if (!data.closed || data.closed === "false") {
          this.setState({
            classWorkingHeddin: "swich_button",
            closedState: "false"
          });
        } else {
          this.setState({
            classWorkingHeddin: "swich_button_hidden",
            closedState: "true"
          });

        }
        this.props.initialize(initData);
      });
  }

  handleSubmit = event => {
    event.preventDefault();
  };

  handleSunc = event => {
    this.setState({ openMassege: true });
    this.setState({ openMassegeAlert: true });
    this.setState({notificationMessage : "Syncing ...!"})
    const request = new Request(
      process.env.REACT_APP_CLIENT_URL + "/api/sync",
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "X-Authorization-Token": "12b20fa6cca0ee113dc92d16f6be3029",
          Authorization: "Bearer " + localStorage.getItem("token")
        })
      }
    );
    fetch(request)
      .then(response => response.json())
      .then(data => {
        if ( data.success === false ){
          this.setState({notificationMessage : "Error there is another sync process running!"})
          this.setState({ openMassege: false });
        }else{
          this.setState({ openMassege: false });
          this.setState({ openMassegeAlert: false });
        }
        
      });
  };

  render() {
    const { classes, submitting, handleSubmit } = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader
          title="General Setting"
          className={classes.header}
          style={{ fontSize: "42px" }}
        />
        <MuiThemeProvider theme={themE}>
          <div>
            <form onSubmit={handleSubmit(submit)}>
              {/* <div>
                <Field
                  name="about"
                  fullWidth={true}
                  multiLine={true}
                  rows={3}
                  rowsMax={5}
                  component={renderTextField}
                  label="About section"
                />
              </div>
              <div>
                <Field
                  name="about_ar"
                  fullWidth={true}
                  multiLine={true}
                  rows={3}
                  rowsMax={5}
                  component={renderTextField}
                  label="About section in ARABIC"
                />
              </div>
              <div>
                <Field
                  name="contacts"
                  fullWidth={true}
                  multiLine={true}
                  rows={3}
                  rowsMax={5}
                  component={renderTextField}
                  label="Contacts section"
                />
              </div>
              <div>
                <Field
                  name="contacts_ar"
                  fullWidth={true}
                  rowsMax={5}
                  multiLine={true}
                  rows={3}
                  component={renderTextField}
                  label="Contacts section in ARABIC"
                />
              </div>
              <div>
                <Field
                  name="facebook"
                  fullWidth={true}
                  component={renderTextField}
                  label="Facebook URL"
                />
              </div>
              <div>
                <Field
                  name="instagram"
                  fullWidth={true}
                  component={renderTextField}
                  label="Instagram URL"
                />
              </div>
              <div>
                <Field
                  name="snapchat"
                  fullWidth={true}
                  component={renderTextField}
                  label="Snapchat URL"
                />
              </div>

              <div>
                <Field
                  name="discount"
                  type="number"
                  component={renderTextField}
                  onChange={event => {
                    if (event.target.value > 0) {
                      this.setState({ classHeddin: "input" });
                    } else {
                      this.setState({ classHeddin: "input_hidden" });
                    }
                  }}
                  label="Global cart discount %"
                />
              </div>

              <div>
                <DateInput
                  className={classes[this.state.classHeddin]}
                  label="Valid until"
                  source="valid_until"
                  options={{ disablePast: true }}
                />
              </div> */}

              {/* <div>
                <FormControlLabel
                  control={
                    <Field
                      name="closed"
                      component={renderRadio}
                      onChange={event => {
                        console.log(event.target.value);
                        console.log(this.state.closedState);
                        if (this.state.closedState === "true") {
                          this.setState({ classWorkingHeddin: "swich_button" });
                          this.setState({ closedState: "false" });
                        } else {
                          this.setState({ classWorkingHeddin: "swich_button_hidden" });
                          this.setState({ closedState: "true" });
                        }
                      }}
                    />
                  }
                  label="Close ( Stop receive orders ) "
                  labelPlacement="start"
                  classes={{
                    label: classes.swich_button
                  }}
                  className={classes.swich_button}
                />
              </div> */}
              {/* <div>
                <FormControlLabel
                  // className={classes[this.state.classWorkingHeddin]}
                  control={
                    <div>
                      <TimeInput
                        // className={classes[this.state.classWorkingHeddin]}
                        label="From"
                        source="open_from"
                      />
                      <TimeInput
                        // className={classes[this.state.classWorkingHeddin]}
                        label="To"
                        source="open_to"
                      />
                    </div>
                  }
                  label="Working hours "
                  labelPlacement="start"
                  classes={{
                    label: (this.state.closedState  === "false") ? classes.swich_button : classes.swich_button_hidden
                  }}
                  className={(this.state.closedState  === "false") ? classes.swich_button : classes[this.state.classWorkingHeddin]}
                />
              </div>
              <div>
                <Field
                  name="closedMessage"
                  fullWidth={true}
                  component={renderTextField}
                  label="Closed (Stop receiving orders message )"
                  className={(this.state.closedState === "true") ? classes.input : classes.input_hidden}
                />
              </div>
              <div>
                <Field
                  className={(this.state.closedState  === "true") ? classes.input_hidden : classes.input}
                  name="closedMessageOutTime"
                  fullWidth={true}
                  component={renderTextField}
                  label="Message for stop receiving orders out of working hours!"
                />
              </div> */}


              <div>
                {/* <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  label="Save"
                  disabled={submitting}
                >
                  <SaveIcon />
                  Save
                </Button> */}

                <Button
                  className={classes.sync_button}
                  color="secondary"
                  variant="contained"
                  label="Sunc"
                  disabled={this.state.openMassege}
                  onClick={() => {
                    this.handleSunc();
                  }}
                >
                  <SyncIcon />
                  Sync POS Data
                </Button>
              </div>
            </form>

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              open={submitting}
              autoHideDuration={6000}
              message={<span id="message-id">Saving ... !</span>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              open={this.state.openMassegeAlert}
              autoHideDuration={6000}
            message={<span id="message-id">{this.state.notificationMessage}</span>}
            />
          </div>
        </MuiThemeProvider>
      </Card>
    );
  }
}

export default reduxForm({
  form: "GeneralNotification",
  validate
})(withStyles(styles)(GeneralNotification));
