import React, { Component, Fragment } from "react";

import { Button } from "react-admin";
import LinearProgress from "@material-ui/core/LinearProgress";

import IconContentAdd from "@material-ui/icons/Sync";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import dataProvider from "../dataProvider";

class SyncOption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            started: false,
            messageText: false,
            messageType: false,
            showDialog: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit, reset } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit("post-quick-create");
    };

    handleSubmit = values => {
        let self = this;
        if (this.state.started) {
            return;
        }
        this.setState({ started: 1 });
        const request = new Request(
            process.env.REACT_APP_CLIENT_URL +
                `/api/SyncOptionSingle/${values.id}`,
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "X-Authorization-Token": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Client-Type": "web",
                    "Access-Control-Allow-Origin": "*"
                })
            }
        );
        fetch(request)
            .then(function(response) {
                return response.json();
            })
            .then(function(myJson) {
                if (myJson.success) {
                    self.setState({
                        started: false,
                        messageText: "Sync has been completed successfully",
                        messageType: "success"
                    });
                } else {
                    self.setState({
                        started: false,
                        messageText:
                            "There is already sync process in progress wait 20 minute and try again",
                        messageType: "error"
                    });
                }
                console.log(myJson);
            });
    };

    render() {
        const { showDialog } = this.state;
        const { isSubmitting, record } = this.props;

        return (
            <Fragment>
                <Button onClick={this.handleClick} label="Sync now">
                    <IconContentAdd />
                </Button>
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    fullWidth
                    onEntered={() => this.handleSubmit(record)}
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label={`Send Notification or SMS to ${record.name}`}
                >
                    <DialogTitle>
                        "{`Syncing (${record.name}) branch data in progress...`}
                        "
                    </DialogTitle>
                    <DialogContent>
                        {!this.state.messageText ? (
                            <LinearProgress />
                        ) : (
                            <div
                                style={{
                                    color:
                                        this.state.messageType == "success"
                                            ? "green"
                                            : "red"
                                }}
                            >
                                {this.state.messageText}
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {this.state.messageText && (
                            <Button
                                label="Close"
                                onClick={this.handleCloseClick}
                            >
                                <IconCancel />
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export default SyncOption;
