import React, { Component, Fragment } from 'react';
import { GET_LIST, GET_MANY, Responsive, Title } from 'react-admin';

import Welcome from './Welcome';
import TotalUsers from './TotalUsers';
import TodayOrders from './TodayOrders';
import TotalOrders from './TotalOrders';
import LastSync from './LastSync';
import LastStockSync from './LastStockSync';
import PendingOrders from './PendingOrders';
import UsersHasOrders from './UsersHasOrders';
import MostOrdered from './MostOrdered';
import PendingReviews from './PendingReviews';
import dataProviderFactory from '../dataProvider';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};

class Dashboard extends Component {
  state = {};

  componentDidMount() {
    const aMonthAgo = new Date();
    aMonthAgo.setDate(aMonthAgo.getDate() - 30);

    dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
      (dataProvider) => {
        dataProvider(GET_LIST, 'statistics', {
          filter: { date_gte: aMonthAgo.toISOString() },
          sort: { field: 'date', order: 'DESC' },
          pagination: { page: 1, perPage: 50 },
        }).then((response) => {
          console.log(response);
          if (response.status === 403) {
            throw new Error('This user is not an Administrator');
          }
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }

          this.setState({
            users: response.data.users,
            nbTodayOrders: response.data.todayOrders,
            nblatest_sync: response.data.latest_sync,
            nblast_stock_sync: response.data.last_stock_sync,
            nbTotalOrders: response.data.totalOrders,
            mostOrdered: response.data.mostOrdered,
            usersHasOrders: response.data.usersHasOrders,
            pendingOrders: response.data.pendingOrders,
          });
        });
      },
    );
  }

  render() {
    const {
      nbNewCustomers,
      nbTodayOrders,
      nbTotalOrders,
      nbPendingReviews,
      nblatest_sync,
      nblast_stock_sync,
      pendingOrders,
      usersHasOrders,
      mostOrdered,
      pendingReviewsCustomers,
      users,
    } = this.state;
    return (
      <Fragment>
        <Title title='Basket Store Admin panel' />
        <Responsive
          xsmall={
            <div>
              <div style={styles.flexColumn}>
                <div style={{ marginBottom: '2em' }}>{/* <Welcome /> */}</div>
                <div style={styles.flex}>
                  <TotalUsers value={users} />
                  <TodayOrders value={nbTodayOrders} />
                </div>
                <div style={styles.singleCol}>
                  <PendingOrders orders={pendingOrders} />
                </div>
              </div>
            </div>
          }
          small={
            <div style={styles.flexColumn}>
              <div style={styles.singleCol}>{/* <Welcome /> */}</div>
              <div style={styles.flex}>
                <TotalUsers value={users} />
                <TodayOrders value={nbTodayOrders} />
              </div>
              {/* <div style={styles.singleCol}>
                <PendingOrders
                  orders={pendingOrders}
                  customers={pendingOrdersCustomers}
                />
              </div> */}
            </div>
          }
          medium={
            <div style={styles.flex}>
              <div style={styles.leftCol}>
                <div style={styles.flex}>
                  <TotalUsers value={users} />
                  <TodayOrders value={nbTodayOrders} />
                </div>
                <div style={styles.singleCol}>
                  <PendingOrders orders={pendingOrders} />
                </div>
                {/* <div style={styles.singleCol}>
                  <PendingOrders
                    orders={pendingOrders}
                    customers={pendingOrdersCustomers}
                  />
                </div> */}
              </div>
              <div style={styles.rightCol}>
                <div style={styles.flex}>
                  <TotalOrders value={nbTotalOrders} />
                </div>
                <div style={styles.singleCol}>
                  <UsersHasOrders value={usersHasOrders} />
                </div>
                <div style={styles.singleCol}>
                  <MostOrdered value={mostOrdered} />
                </div>
                <div style={styles.singleCol}>
                  <LastSync value={nblatest_sync} />
                </div>
                <div style={styles.singleCol}>
                  <LastStockSync value={nblast_stock_sync} />
                </div>
                {/* <div style={styles.singleCol}>
                  <PendingOrders
                    orders={pendingOrders}
                    customers={pendingOrdersCustomers}
                  />
                </div> */}
              </div>
            </div>
          }
        />
      </Fragment>
    );
  }
}

export default Dashboard;
