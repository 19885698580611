import React from "react";
import Card from "@material-ui/core/Card";
import Accessibility from "@material-ui/icons/AddShoppingCart";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { translate } from "react-admin";

import CardIcon from "./CardIcon";

const styles = {
    main: {
        flex: "1",
        marginRight: "1em",
        marginTop: 20
    },
    card: {
        overflow: "inherit",
        textAlign: "right",
        padding: 16,
        minHeight: 52
    },
    divItem: {
        fontSize: "15px"
    }
};

const MonthlyRevenue = ({ value, translate, classes }) => (
    <div className={classes.main}>
        <CardIcon Icon={Accessibility} bgColor="#9756be" />
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
                Most ordered product
            </Typography>
            <Typography variant="headline" component="h2">
                {value &&
                    value.map(record => (
                        <div className={classes.divItem}>{record} -</div>
                    ))}
            </Typography>
        </Card>
    </div>
);

export default translate(withStyles(styles)(MonthlyRevenue));
