import React from "react";
import {
    translate,
    Create,
    Datagrid,
    Edit,
    Filter,
    DeleteButton,
    DisabledInput,
    NumberInput,
    SimpleForm,
    required,
    Toolbar,
    SaveButton,
    TextField,
    TextInput
} from "react-admin";
import { TimeInput } from "react-admin-date-inputs";
import List from "../customs/List";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/LocationOn";

export const AreasIcon = Icon;

export const OrdersFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const AreasList = props => (
    <List {...props} filters={<OrdersFilter />}>
        <Datagrid>
            <TextField source="name" />
            <TextField source="fee" />
            {/* <EditButton /> */}
            {/* <DeleteButton /> */}
        </Datagrid>
    </List>
);

const createStyles = {
    stock: { width: "5em" },
    price: { width: "5em" },
    width: { width: "5em" },
    widthFormGroup: { display: "inline-block" },
    height: { width: "5em" },
    heightFormGroup: { display: "inline-block", marginLeft: 32 }
};

export const AreasCreate = withStyles(createStyles)(({ classes, ...props }) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput
                className={classes.input}
                source="name"
                validate={required()}
            />
            <TextInput
                className={classes.input}
                source="name_ar"
                validate={required()}
            />
            <NumberInput
                className={classes.input}
                source="fee"
                validate={required()}
            />
        </SimpleForm>
    </Create>
));

const AreasTitle = translate(({ record, translate }) => (
    <span>
        {translate("Areas.name")} #{record.id}
    </span>
));

const editStyles = {
    ...createStyles,
    comment: {
        maxWidth: "20em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
};

const toolbarStyles = {
    toolbar: {
        display: "flex",
        justifyContent: "space-between"
    }
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

export const AreasEdit = withStyles(editStyles)(({ classes, ...props }) => (
    <Edit {...props} title={<AreasTitle />}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
            <TextInput
                className={classes.input}
                source="name"
                validate={required()}
            />
            <TextInput
                className={classes.input}
                source="name_ar"
                validate={required()}
            />
            <TextInput
                className={classes.input}
                source="fee"
                validate={required()}
            />
        </SimpleForm>
    </Edit>
));
