import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR } from 'react-admin';

export default (type, params) => {

    if (type === AUTH_LOGIN) {

        const { username, password } = params;
   
        const request = new Request(process.env.REACT_APP_CLIENT_URL + '/api/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'X-Source': 'admin',
                'Access-Control-Allow-Origin': '*'
            }),
        })
        return fetch(request)
        .then(response => {
            if (response.status === 403) {
                throw new Error('This user is not an Administrator');
            }
            if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                
                return response.json();
            })
            .then(({ token, user_id, is_admin }) => {
                console.log(token);
                   localStorage.setItem('token', token);
                   localStorage.setItem('user_id', user_id);
                //    let checkAdmin = ( user.is_sub_admin ===1 && user.is_admin !== 1 ) ? 1 :0;
                //    let checkAdmin = ( is_admin ) ? 1 :0;
                //    localStorage.setItem('subAdmin', checkAdmin);
                    return Promise.resolve();
            });

    }
    if (type === AUTH_LOGOUT) {
        localStorage.clear();
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ?
            Promise.resolve() :
            Promise.reject();
    }
    return Promise.reject('Unkown method');
};