import React from "react";
import {
    translate,
    Create,
    Datagrid,
    Edit,
    EditButton,
    DeleteButton,
    ImageInput,
    ImageField,
    SimpleForm,
    required,
    Toolbar,
    SaveButton,
    TextField,
    TextInput
} from "react-admin";
import { TimeInput } from "react-admin-date-inputs";
import List from "../customs/List";
import withStyles from "@material-ui/core/styles/withStyles";
import SyncOption from "./SyncOption";
import Poster from "./Poster";
import Icon from "@material-ui/icons/StoreMallDirectory";

export const MarketsIcon = Icon;
export const MarketsList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="name" />
            <TextField source="name_ar" />
            <TextField source="latestSunc" label="Latest sync on" />
            <SyncOption />
            <EditButton />
            {/* <DeleteButton /> */}
        </Datagrid>
    </List>
);

const createStyles = {
    stock: { width: "5em" },
    price: { width: "5em" },
    width: { width: "5em" },
    widthFormGroup: { display: "inline-block" },
    height: { width: "5em" },
    heightFormGroup: { display: "inline-block", marginLeft: 32 }
};

export const MarketsCreate = withStyles(createStyles)(
    ({ classes, ...props }) => (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput
                    className={classes.input}
                    source="name"
                    validate={required()}
                />
                <TextInput
                    className={classes.input}
                    source="name_ar"
                    validate={required()}
                />
                <TextInput className={classes.input} source="description" />
                <TextInput className={classes.input} source="description_ar" />
                <ImageInput
                    source="image"
                    label="Branch Photo"
                    placeholder={<p>The image should be 1000 * 500 PX.</p>}
                    accept="image/*"
                    validate={required()}
                >
                    <ImageField source="image" title="title" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
);

const MarketsTitle = translate(({ record, translate }) => (
    <span>
        {translate("Markets.name")} #{record.id}
    </span>
));

const editStyles = {
    ...createStyles,
    comment: {
        maxWidth: "20em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
};

const toolbarStyles = {
    toolbar: {
        display: "flex",
        justifyContent: "space-between"
    }
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

export const MarketsEdit = withStyles(editStyles)(({ classes, ...props }) => (
    <Edit {...props} title={<MarketsTitle />}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
            <TextInput
                className={classes.input}
                source="name"
                validate={required()}
            />
            <TextInput
                className={classes.input}
                source="name_ar"
                validate={required()}
            />
            <TextInput className={classes.input} source="description" />
            <TextInput className={classes.input} source="description_ar" />
            <Poster />
        </SimpleForm>
    </Edit>
));
