import React from 'react';
import {
  translate,
  Create,
  Datagrid,
  Edit,
  EditButton,
  ReferenceArrayField,
  FormTab,
  Filter,
  TabbedForm,
  CardActions,
  RefreshButton,
  ReferenceInput,
  SelectInput,
  DisabledInput,
  FilterButton,
  NumberInput,
  ImageInput,
  ImageField,
  ReferenceArrayInput,
  LongTextInput,
  SelectArrayInput,
  TextField,
  ChipField,
  SingleFieldList,
  TextInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  required,
} from 'react-admin';
import List from '../customs/List';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Fastfood';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import Poster from './Poster';
import PosterAr from './PosterAr';
import PosterMobile from './PosterMobile';
import PosterArMobile from './PosterArMobile';
export const ProductsIcon = Icon;

function booleanCheck(val) {
  if (val) {
    return <Check />;
  } else {
    return <Close />;
  }
}
const QuickFilter = translate(({ label, translate }) => (
  <Chip>{translate(label)}</Chip>
));

export const ProductsFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
    {/* <ReferenceInput
            source="category_id"
            reference="productCategory"
            sort={{ field: "id", order: "ASC" }}
        >
            <SelectInput source="name" />
        </ReferenceInput> */}
    <NumberInput source='price' />
  </Filter>
);

const ActionsRefresh = (props) => (
  <CardActions>
    <RefreshButton />
    <FilterButton source={ProductsFilter} />
  </CardActions>
);

const listStyles = {
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  image: {
    width: '200px',
  },
  CardActions: {
    disblay: 'none',
  },
};

export const ProductsList = withStyles(listStyles)(({ classes, ...props }) => (
  <List title='Prodcuts List' {...props} filters={<ProductsFilter />}>
    <Datagrid>
      <TextField source='name' />
      <ImageField
        sortable={false}
        source='photo'
        classes={{
          image: classes.image,
        }}
        title='picture.title'
      />
      <ReferenceArrayField
        sortable={false}
        label='Category'
        source='category_ids'
        reference='productCategory'
      >
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
        {/* <TextField optionText="name" optionValue="id" /> */}
      </ReferenceArrayField>
      <TextField source='price' />
      <TextField source='quantity' />
      <TextField source='pos_stock' />
      {/* <TextField source="discount" /> */}
      {/* <TextField source="percentage" /> */}
      <EditButton />
    </Datagrid>
  </List>
));

const createStyles = {
  stock: { width: '5em' },
  fullWidth: { width: '100%' },
  price: { width: '5em' },
  width: { width: '5em' },
  label: { color: 'red' },
  widthFormGroup: { display: 'inline-block' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

export const ProductsCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props} title='Create porduct' classes={classes}>
      <TabbedForm redirect='list'>
        <FormTab label='English Content'>
          <TextInput
            source='name'
            className={classes.fullWidth}
            label='Product name'
            validate={required()}
          />
          <LongTextInput source='description' />
          <LongTextInput source='brief_description' />
        </FormTab>
        <FormTab label='Arabic Content'>
          <TextInput
            source='name_ar'
            className={classes.fullWidth}
            label='Product name in arabic'
            validate={required()}
          />
          <LongTextInput
            source='description_ar'
            label='Description in arabic'
          />
          <LongTextInput
            source='brief_description_ar'
            label='Brief description in arabic'
          />
        </FormTab>
        <FormTab label='Product properties'>
          <ReferenceInput
            source='market_id'
            label='On market'
            reference='markets'
            validate={required()}
          >
            <SelectInput optionText='name' optionValue='id' />
          </ReferenceInput>

          <ReferenceArrayInput
            source='categories'
            label='Productg Categories'
            reference='productCategory'
            allowEmpty
          >
            <SelectArrayInput optionText='name' optionValue='id' />
          </ReferenceArrayInput>
          <TextInput
            source='brand_name'
            className={classes.fullWidth}
            validate={required()}
          />
          <NumberInput source='price' validate={required()} />
          <NumberInput source='quantity' validate={required()} />
          <NumberInput source='total_quantity' validate={required()} />
          <NumberInput source='discount' validate={required()} />
          <NumberInput source='percentage' validate={required()} />
          <ImageInput
            source='images'
            label='Product photos'
            accept='image/*'
            multiple={true}
            validate={required()}
          >
            <ImageField source='image_mobile_ar' title='title' />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Create>
  ),
);

const ProductsTitle = translate(({ record, translate }) => (
  <span>
    {translate('products.name')} #{record.id}
  </span>
));

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

export const ProductsEdit = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Edit {...props} title={<ProductsTitle />}>
      <TabbedForm redirect='list' toolbar={<CustomToolbar />}>
        <FormTab label='English Content'>
          <DisabledInput
            source='name'
            className={classes.fullWidth}
            label='Product name'
          />
          <LongTextInput source='description' />
          <LongTextInput source='brief_description' />
        </FormTab>
        <FormTab label='Arabic Content'>
          <DisabledInput
            source='name_ar'
            className={classes.fullWidth}
            label='Product name in arabic'
            validate={required()}
          />
          <LongTextInput
            source='description_ar'
            label='Description in arabic'
          />
          <LongTextInput
            source='brief_description_ar'
            label='Brief description in arabic'
          />
        </FormTab>
        <FormTab label='Product properties'>
          <ReferenceInput
            source='market_id'
            label='On market'
            reference='markets'
            validate={required()}
          >
            <SelectInput disabled optionText='name' optionValue='id' />
          </ReferenceInput>
          <ReferenceArrayInput
            source='categories'
            label='Productg Categories'
            reference='productCategory'
            allowEmpty
          >
            <SelectArrayInput disabled optionText='name' optionValue='id' />
          </ReferenceArrayInput>
          <TextInput
            source='brand_name'
            className={classes.fullWidth}
            validate={required()}
          />
          <NumberInput source='price' disabled validate={required()} />
          <NumberInput source='quantity' validate={required()} />
          <NumberInput source='total_quantity' validate={required()} />
          <NumberInput source='discount' validate={required()} />
          <NumberInput source='percentage' validate={required()} />
          <Poster />
        </FormTab>
      </TabbedForm>
    </Edit>
  ),
);
