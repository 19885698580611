import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import withStyles from "@material-ui/core/styles/withStyles";
import { ImageInput, ImageField } from "react-admin";

const styles = {
    root: { display: "inline-block", marginTop: "1em", zIndex: 2 },
    content: { padding: 0, "&:last-child": { padding: 0 } },
    img: {
        width: "initial",
        minWidth: "initial",
        maxWidth: "42em",
        maxHeight: "15em"
    }
};

const Poster = withStyles(styles)(({ classes, record }) => (
    <Card className={classes.root}>
        <ImageInput
            source="photos"
            label="Branch Photo"
            accept="image/*"
            placeholder={<p>The image should be 1000 * 500 PX.</p>}
        >
            <ImageField source="photos" title="title" />
        </ImageInput>
        <CardContent className={classes.content}>
            <img src={record.photos} alt="" className={classes.img} />
        </CardContent>
    </Card>
));

export default Poster;
