import React from "react";
import { Route } from "react-router-dom";
import Configuration from "./configuration/Configuration";
import ProdcutsOrder from "./ProdcutsOrder/prodcutsOrder";
import GeneralNotification from "./generalNotification/GeneralNotification";
import HomeData from "./homeData/HomeData";
import SMS from "./SMS";
import Segments from "./segments/Segments";

export default [
    // <Route exact path="/configuration" component={Configuration} />,
    <Route exact path="/homeData" component={HomeData} />,
    <Route exact path="/segments" component={Segments} />,
    <Route exact path="/productsorder" component={ProdcutsOrder} />,
    <Route exact path="/generalNotification" component={GeneralNotification} />,
    <Route exact path="/sms" component={SMS} />
];
