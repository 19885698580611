import React from "react";
import {
    translate,
    Create,
    Datagrid,
    Edit,
    EditButton,
    DeleteButton,
    Toolbar,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SaveButton,
    ImageInput,
    ImageField,
    FormTab,
    required,
    Filter,
    CardActions,
    RefreshButton,
    FilterButton,
    TabbedForm,
    TextField,
    TextInput
} from "react-admin";
import List from "../customs/List";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/Category";
import Poster from "./Poster";

export const ProductCategoryIcon = Icon;
const listStyles = {
    root: {
        background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        borderRadius: 3,
        border: 0,
        color: "white",
        height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)"
    },
    image: {
        width: "200px"
    },
    CardActions: {
        disblay: "none"
    }
};
export const ProductsFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const ActionsRefresh = props => (
    <CardActions>
        <RefreshButton />
        <FilterButton source={ProductsFilter} />
    </CardActions>
);

export const ProductCategoryList = withStyles(listStyles)(
    ({ classes, ...props }) => (
        <List {...props} filters={<ProductsFilter />}>
            <Datagrid>
                <TextField source="name" />
                <ImageField
                    sortable={false}
                    source="image"
                    classes={{
                        image: classes.image
                    }}
                />
                <ReferenceField
                    label="Parent category"
                    source="parent_id"
                    reference="productCategory"
                    linkType={false}
                    allowEmpty
                >
                    <TextField source="name" />
                </ReferenceField>

                <EditButton />
            </Datagrid>
        </List>
    )
);

const createStyles = {
    stock: { width: "5em" },
    price: { width: "5em" },
    width: { width: "5em" },
    widthFormGroup: { display: "inline-block" },
    height: { width: "5em" },
    heightFormGroup: { display: "inline-block", marginLeft: 32 }
};

export const ProductCategoryCreate = withStyles(createStyles)(
    ({ classes, ...props }) => (
        <Create {...props}>
            <TabbedForm redirect="list">
                <FormTab label="English Content">
                    <TextInput source="name" />
                </FormTab>
                <FormTab label="Arabic Content">
                    <TextInput source="name_ar" />
                </FormTab>
                <FormTab label="Properites">
                    <ReferenceInput
                        source="parent_id"
                        label="Parent category"
                        reference="productCategory"
                        allowEmpty
                    >
                        <SelectInput optionText="name" optionValue="id" />
                    </ReferenceInput>
                    <ImageInput
                        source="image"
                        label="The photo"
                        accept="image/*"
                        validate={required()}
                    >
                        <ImageField source="image" title="title" />
                    </ImageInput>
                </FormTab>
            </TabbedForm>
        </Create>
    )
);

const ProductCategoryTitle = translate(({ record, translate }) => (
    <span>
        {translate("ProductCategory.name")} #{record.id}
    </span>
));

const editStyles = {
    ...createStyles,
    comment: {
        maxWidth: "20em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
};

const toolbarStyles = {
    toolbar: {
        display: "flex",
        justifyContent: "space-between"
    }
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

export const ProductCategoryEdit = withStyles(editStyles)(
    ({ classes, ...props }) => (
        <Edit {...props} title={<ProductCategoryTitle />}>
            <TabbedForm redirect="list" toolbar={<CustomToolbar />}>
                <FormTab label="English Content">
                    <TextInput source="name" disabled />
                </FormTab>
                <FormTab label="Arabic Content">
                    <TextInput source="name_ar" disabled />
                </FormTab>
                <FormTab label="Properites">
                    <ReferenceInput
                        source="parent_id"
                        label="Parent category"
                        reference="productCategory"
                        allowEmpty
                    >
                        <SelectInput
                            disabled
                            optionText="name"
                            optionValue="id"
                        />
                    </ReferenceInput>
                    <Poster />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
);
