import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import withStyles from "@material-ui/core/styles/withStyles";
import { ImageInput, ImageField, TextInput } from "react-admin";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

const styles = {
    root: { display: "inline-block", marginTop: "1em", zIndex: 2 },
    content: { padding: 0, "&:last-child": { padding: 0 } },
    img: {
        width: "initial",
        minWidth: "initial",
        maxWidth: "42em",
        maxHeight: "15em"
    }
};
function deletePhoto(e, id) {
    e.preventDefault();
    sendDelPhoto(id);
    document.getElementById(id).remove();
}

function sendDelPhoto(id, photo) {
    console.log(photo);
    const request = new Request(
        process.env.REACT_APP_CLIENT_URL +
        "/api/backend/deleteImage/" +
        id ,
        {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "X-Authorization-Token": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Client-Type": "web",
                "Access-Control-Allow-Origin": "*"
            })
        }
    );
    return fetch(request);
}
const Poster = withStyles(styles)(({ classes, record }) => (
    <Card className={classes.root}>
        <CardContent className={classes.content} id="image_photo">
            {record.images &&
                record.images.map(img => (
                    <div id={img.id}>
                        <img src={img.name} alt="" style={{width:'300px'}} className={classes.img}  />
                        <Button onClick={(e) => deletePhoto(e, img.id)}  ><DeleteIcon style={{ color: "red" }} /></Button>
                    </div>

                ))}
        </CardContent>
    </Card>
));

export default Poster;
