import React from "react";
import compose from "recompose/compose";
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import UserIcon from "@material-ui/icons/Restaurant";
import annoUser from "../assets/user.png";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { translate } from "react-admin";
import CardIcon from "./CardIcon";
import Typography from "@material-ui/core/Typography";

const style = theme => ({
    root: {
        flex: 1
    },
    avatar: {
        background: theme.palette.background.avatar
    },
    cost: {
        marginRight: "1em",
        color: theme.palette.text.primary
    },
    main: {
        flex: "1",
        marginRight: "1em",
        marginTop: 20
    },
    card: {
        overflow: "inherit",
        textAlign: "right",
        padding: 16,
        minHeight: 52
    }
});

const PendingOrders = ({
    orders = [],
    customers = {},
    nb,
    translate,
    classes
}) => (
    <div className={classes.main}>
        <CardIcon Icon={UserIcon} bgColor="red" />
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
                {translate("orders.pending")}
            </Typography>
            <Typography
                variant="headline"
                component="h2"
                className={classes.value}
            >
                {nb}
            </Typography>
            <Divider />
            <List dense={true}>
                {orders.map(record => (
                    <ListItem
                        key={record.id}
                        button
                        component={Link}
                        to={`/orders/${record.id}/show/`}
                    >
                        {record.user && record.user.photo !== null ? (
                            <Avatar
                                className={classes.avatar}
                                src={`${record.user.photo}?size=32x32`}
                            />
                        ) : (
                            <Avatar>
                                <AccountCircleIcon />
                            </Avatar>
                        )}
                        <ListItemText
                            primary={new Date(record.created_at).toLocaleString(
                                "en-GB"
                            )}
                            secondary={translate("pos.dashboard.order.items", {
                                smart_count: record.count,
                                nb_items: record.count,
                                customer_name: record.user
                                    ? record.user.name
                                    : ""
                            })}
                        />
                        <ListItemSecondaryAction>
                            <span className={classes.cost}>
                                {record.total_price} EGP
                            </span>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Card>
    </div>
);

const enhance = compose(withStyles(style), translate);

export default enhance(PendingOrders);
