import React from "react";
import {
    translate,
    Create,
    Datagrid,
    Edit,
    EditButton,
    DeleteButton,
    FormTab,
    Filter,
    TabbedForm,
    ImageInput,
    ImageField,
    TextField,
    TextInput,
    required
} from "react-admin";
import List from "../customs/List";
import Chip from "@material-ui/core/Chip";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/PhotoAlbum";

import Poster from "../posters/Poster";
import PosterAr from "../posters/PosterAr";
export const GalleryIcon = Icon;

const QuickFilter = translate(({ label, translate }) => (
    <Chip>{translate(label)}</Chip>
));

export const GalleryFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const GalleryList = props => (
    <List {...props} filters={<GalleryFilter />}>
        <Datagrid>
            <TextField source="name" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

const createStyles = {
    stock: { width: "5em" },
    price: { width: "5em" },
    width: { width: "5em" },
    widthFormGroup: { display: "inline-block" },
    height: { width: "5em" },
    heightFormGroup: { display: "inline-block", marginLeft: 32 }
};

const handleChangeImage = evt => {};

export const GalleryCreate = withStyles(createStyles)(
    ({ classes, ...props }) => (
        <Create {...props}>
            <TabbedForm redirect="list">
                <FormTab label="English Content">
                    <TextInput source="name" validate={required()} />
                    <ImageInput
                        source="image"
                        label="The photo"
                        accept="image/*"
                        validate={required()}
                    >
                        <ImageField source="image" title="title" />
                    </ImageInput>
                </FormTab>
                <FormTab label="Arabic Content">
                    <TextInput source="name_ar" validate={required()} />
                    <ImageInput
                        source="image_ar"
                        label="The photo"
                        accept="image/*"
                        validate={required()}
                    >
                        <ImageField source="image_ar" title="title" />
                    </ImageInput>
                </FormTab>
            </TabbedForm>
        </Create>
    )
);

const GalleryTitle = translate(({ record, translate }) => (
    <span>
        {translate("gallery.name")} #{record.id}
    </span>
));

const editStyles = {
    ...createStyles,
    comment: {
        maxWidth: "20em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
};

export const GalleryEdit = withStyles(editStyles)(({ classes, ...props }) => (
    <Edit {...props} title={<GalleryTitle />}>
        <TabbedForm redirect="list">
            <FormTab label="English Content">
                <TextInput source="name" />
                <Poster />
            </FormTab>
            <FormTab label="Arabic Content">
                <TextInput source="name_ar" />
                <PosterAr />
            </FormTab>
        </TabbedForm>
    </Edit>
));
