import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import {ImageInput,ImageField} from "react-admin";

const styles = {
    root: { display: 'inline-block', marginTop: '1em', zIndex: 2 },
    content: { padding: 0, '&:last-child': { padding: 0 } },
    img: {
        width: 'initial',
        minWidth: 'initial',
        maxWidth: '42em',
        maxHeight: '15em',
    },
};

const PosterAr = withStyles(styles)(({ classes, record }) => (
    <Card className={classes.root}>
        <ImageInput
          source="image_ar"
          label="Photo for website in Arabic"
          accept="image/*"
          placeholder={<p>If you want to change the photo drop your file here or click.</p>}
        >
          <ImageField source="image_ar" title="title" />
        </ImageInput>
        <CardContent className={classes.content}>
            <img src={record.photo_ar} alt="" className={classes.img} />
        </CardContent>
    </Card>
));

export default PosterAr;
