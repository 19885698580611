import React from "react";
import { Layout } from "react-admin";
import { connect } from "react-redux";
import MyAppBar from "./MyAppBar";

const MyLayout = props => <Layout {...props} appBar={MyAppBar} />;

const darkTheme = {
    palette: {
        type: "dark" // Switching the dark mode on is a single property value change.
    }
};

const lightTheme = {
    palette: {
        secondary: {
            light: "#5f5fc4",
            main: "#8b58a4",
            dark: "#001064",
            contrastText: "#fff"
        }
    }
};

export default connect(
    state => ({
        theme: state.theme === "dark" ? darkTheme : lightTheme
    }),
    {}
)(MyLayout);
// export default MyLayout;
