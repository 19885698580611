import "babel-polyfill";
import React, { Component } from "react";
import { Admin, Resource } from "react-admin";
import "./App.css";
import authProvider from "./authProvider";
import sagas from "./sagas";
import themeReducer from "./themeReducer";
import Login from "./Login";
import Layout from "./Layout";
import Menu from "./Menu";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";
import { SliderList, SliderCreate, SliderEdit, SliderIcon } from "./slider";
import {
    GalleryList,
    GalleryCreate,
    GalleryEdit,
    GalleryIcon
} from "./gallery";
import { ProductsList, ProductsEdit, ProductsIcon } from "./products";
import {
    OrdersList,
    OrdersCreate,
    OrdersEdit,
    OrderShow,
    OrdersIcon
} from "./orders";
import {
    ProductCategoryList,
    ProductCategoryEdit,
    ProductCategoryCreate,
    ProductCategoryIcon
} from "./productCategory";
import {
    UsersList,
    UsersCreate,
    UsersEdit,
    UserShow,
    UsersIcon
} from "./users";
import { AreasList, AreasCreate, AreasEdit, AreasIcon } from "./areas";
import {
    MarketsList,
    MarketsCreate,
    MarketsEdit,
    MarketsIcon
} from "./markets";
import { PromoList, PromoCreate, PromoEdit, PromoIcon } from "./promoCodes";
import { ContactUsList, ContactUsShow, ContactUsIcon } from "./contactUs";
import createHistory from "history/createBrowserHistory";
import dataProviderFactory from "./dataProvider";
import MyLayout from "./MyLayout";

const i18nProvider = locale => {
    if (locale === "fr") {
        return import("./i18n/fr").then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
};

class App extends Component {
    state = { dataProvider: null };

    constructor(props) {
        super(props);
        this.state = {
            inputValue: ""
        };
    }
    async componentWillMount() {
        // this.checkAuth(this);
        // this.checkAuth2(this);
        const dataProvider = await dataProviderFactory(
            process.env.REACT_APP_DATA_PROVIDER
        );

        this.setState({ dataProvider });
    }

    componentWillUnmount() {
        this.restoreFetch();
    }

    checkAuth(that) {
        const request = new Request(
            process.env.REACT_APP_CLIENT_URL + "/api/checkToken",
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "X-Authorization-Token":
                        "12b20fa6cca0ee113dc92d16f6be3029m",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Access-Control-Allow-Origin": "*"
                })
            }
        );
        return fetch(request)
            .then(function(response) {
                return response.json();
            })
            .then(function(myJson) {
                if (
                    localStorage.getItem("token") &&
                    myJson.error &&
                    myJson.error === "Unauthenticated."
                ) {
                    localStorage.clear();

                    const h = createHistory();
                    h.push("/#login", { some: "state" });

                    that.forceUpdate();
                }
            });
    }
    checkAuth2(that) {
        const request2 = new Request(
            process.env.REACT_APP_CLIENT_URL + "/api/products",
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "X-Authorization-Token":
                        "12b20fa6cca0ee113dc92d16f6be3029m",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Access-Control-Allow-Origin": "*"
                })
            }
        );
        return fetch(request2)
            .then(function(response) {
                return response.json();
            })
            .then(function(myJson) {
                const myObjStr = JSON.stringify(myJson);
                localStorage.setItem("products", myObjStr);
            });
    }

    render() {
        const { dataProvider } = this.state;

        if (!dataProvider) {
            return (
                <div className="loader-container">
                    <div className="loader">Loading...</div>
                </div>
            );
        }
        if (localStorage.getItem("subAdmin") !== "1") {
            return (
                <Admin
                    title="Basket Store Admin Panel"
                    dataProvider={dataProvider}
                    customReducers={{ theme: themeReducer }}
                    customSagas={sagas}
                    customRoutes={customRoutes}
                    authProvider={authProvider}
                    dashboard={Dashboard}
                    loginPage={Login}
                    appLayout={MyLayout}
                    menu={Menu}
                    locale="en"
                    i18nProvider={i18nProvider}
                >
                    <Resource
                        name="slider"
                        list={SliderList}
                        create={SliderCreate}
                        edit={SliderEdit}
                        icon={SliderIcon}
                    />
                    <Resource
                        name="gallery"
                        list={GalleryList}
                        create={GalleryCreate}
                        edit={GalleryEdit}
                        icon={GalleryIcon}
                    />
                    <Resource
                        name="products"
                        list={ProductsList}
                        edit={ProductsEdit}
                        icon={ProductsIcon}
                    />

                    <Resource
                        name="productCategory"
                        list={ProductCategoryList}
                        edit={ProductCategoryEdit}
                        icon={ProductCategoryIcon}
                    />
                    <Resource
                        name="users"
                        list={UsersList}
                        create={UsersCreate}
                        edit={UsersEdit}
                        show={UserShow}
                        icon={UsersIcon}
                    />
                    <Resource
                        name="areas"
                        list={AreasList}
                        // create={AreasCreate}
                        // edit={AreasEdit}
                        icon={AreasIcon}
                    />

                    <Resource
                        name="markets"
                        list={MarketsList}
                        create={MarketsCreate}
                        edit={MarketsEdit}
                        icon={MarketsIcon}
                    />
                    <Resource
                        name="promoCodes"
                        list={PromoList}
                        create={PromoCreate}
                        edit={PromoEdit}
                        icon={PromoIcon}
                    />
                    <Resource
                        name="contactUs"
                        list={ContactUsList}
                        icon={ContactUsIcon}
                        show={ContactUsShow}
                    />
                    <Resource
                        name="orders"
                        list={OrdersList}
                        edit={OrdersEdit}
                        show={OrderShow}
                        icon={OrdersIcon}
                    />
                </Admin>
            );
        } else {
            return (
                <Admin
                    title="Basket Store Admin Panel"
                    dataProvider={dataProvider}
                    customReducers={{ theme: themeReducer }}
                    customSagas={sagas}
                    customRoutes={customRoutes}
                    authProvider={authProvider}
                    dashboard={Dashboard}
                    loginPage={Login}
                    appLayout={Layout}
                    menu={Menu}
                    locale="en"
                    i18nProvider={i18nProvider}
                >
                    <Resource
                        name="products"
                        list={ProductsList}
                        edit={ProductsEdit}
                        icon={ProductsIcon}
                    />

                    <Resource
                        name="productCategory"
                        list={ProductCategoryList}
                        edit={ProductCategoryEdit}
                        icon={ProductCategoryIcon}
                    />
                    <Resource name="users" show={UserShow} />

                    <Resource
                        name="orders"
                        list={OrdersList}
                        edit={OrdersEdit}
                        show={OrderShow}
                        icon={OrdersIcon}
                    />
                </Admin>
            );
        }
    }
}

export default App;
