import React from "react";
import {
  translate,
  Create,
  Datagrid,
  Edit,
  EditButton,
  DeleteButton,
  FormTab,
  Filter,
  SimpleForm,
  ImageInput,
  ImageField,
  FunctionField,
  TextField,
  TextInput,
  BooleanInput,
  required
} from "react-admin";

import Chip from "@material-ui/core/Chip";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/Collections";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import List from "../customs/List";
import Poster from "./Poster";
export const SliderIcon = Icon;


const QuickFilter = translate(({ label, translate }) => (
  <Chip>{translate(label)}</Chip>
));

export const SliderFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
const listStyles = {
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)"
  },
  image: {
    width: "20px"
  },
  CardActions: {
    disblay: "none"
  }
};

function booleanCheck(val) {
  if (val) {
    return <Check />;
  } else {
    return <Close />;
  }
}
export const SliderList = withStyles(listStyles)(
  ({ classes, ...props }) => (
    <List {...props} filters={<SliderFilter />}>
      <Datagrid>
        <ImageField
          source="photo"
          classes={{
            image: classes.image
          }}
          title="picture.title"
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
);

const createStyles = {
  stock: { width: "5em" },
  price: { width: "5em" },
  width: { width: "5em" },
  widthFormGroup: { display: "inline-block" },
  height: { width: "5em" },
  heightFormGroup: { display: "inline-block", marginLeft: 32 }
};

export const SliderCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props}>
      <SimpleForm label="English Content">
        <ImageInput source="image" label="Slider photo" placeholder={
          <p>The image should be  1000 * 500 PX.</p>
        }
          accept="image/*" validate={required()}>
          <ImageField source="image" title="title" />
        </ImageInput>
      </SimpleForm>

    </Create>
  )
);

const SliderTitle = translate(({ record, translate }) => (
  <span>
    {translate("slider.name")} #{record.id}
  </span>
));

const editStyles = {
  ...createStyles,
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
};

export const SliderEdit = withStyles(editStyles)(
  ({ classes, ...props }) => (
    <Edit {...props} title={<SliderTitle />}>
      <SimpleForm label="English Content">
        <Poster />
      </SimpleForm>

    </Edit>
  )
);
